@import "src/themes/daikiri/styles/index.scss";
.search-select {
  width: 240px;
  position: relative;

  &--active,
  &:hover {
    .search-select__input {
      border-color: getvar($colors, "primary", "200");
    }
  }

  &--fluid {
    width: 100%;

    .search-select__value {
      width: 90%;
    }
  }

  &:focus-within {
    .search-select__input {
      border-color: getvar($colors, "primary", "200");
    }
  }

  &__input {
    box-sizing: border-box;
    border: 1px solid getvar($colors, "neutral", "500");
    border-radius: 4px;
    padding: space(0.5) space(1);
    color: getvar($colors, "neutral", "700");
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 40px;
    border: 1px solid #bfbfbf;
    background-color: white;
  }

  &__value {
    cursor: pointer;
    border: unset;
    outline: unset;
    width: 160px;
    color: getvar($colors, "neutral", "800");
    background-color: transparent;
    line-height: 18px;
    font-size: 14px;
  }

  &__icon {
    position: absolute;
    margin: auto;
    /*    top: 0;
    bottom: 0;
    right: 8px; */
    pointer-events: none;
    height: 1.25rem;
    width: 1.25rem;
    color: #737479;
    position: absolute;
    right: 6px;
    top: 8px;
  }

  &__panel,
  .panel {
    position: absolute;
    right: 0;
    display: none;
    flex-direction: column;
    row-gap: 4px;
    z-index: 100;
    margin-top: 4px;
    height: fit-content;
    min-width: 250px;
    width: 100%;

    &--visible {
      display: flex;
    }
  }
}

.panel {
  &__options {
    box-sizing: border-box;
    padding: space(0.5);
    width: 100%;
    display: flex;
    flex-direction: column;

    height: fit-content;
    max-height: 290px;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: white;
    border: 1px solid getvar($colors, "neutral", "400");
    box-shadow: 0 4px 4px getvar($colors, "neutral", "400");

    &-inner {
      overflow: auto;
    }
  }

  &__search {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid getvar($colors, "neutral", "500");
    border-radius: 4px;
    padding: space(0.5) space(1);
    color: getvar($colors, "neutral", 700);
    width: 100%;
    height: 40px;
    position: relative;
    background-color: white;

    &:focus-within {
      border-color: getvar($colors, "primary", "500");
    }

    &-input {
      border: unset;
      outline: unset;
      width: 90%;
    }

    &-icon {
      width: 16px;
      font-size: 16px;
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      right: 16px;
    }
  }

  &__option {
    box-sizing: border-box;
    width: 100%;
    cursor: pointer;
    border-radius: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 space(0.5);

    font-size: 12px;

    @include xs {
      padding: space(0.5) space(1);
      font-size: 16px;
    }

    &:hover {
      background-color: getvar($colors, "primary", "100");
    }
  }
}
