@import "src/themes/daikiri/styles/index.scss";
.date-picker {
  width: 100%;

  @include md {
    width: 20rem;
  }

  &--fluid {
    width: 100%;
    @include md {
      width: 100%;
    }
  }

  &__label {
    text-align: left;
    display: block;
  }

  &__input-container {
    position: relative;
  }

  &__input {
    position: relative;

    &__box {
      display: block;
      width: 100%;
      height: 50px;
      padding: space(0.75);
      border: 1px solid getvar($colors, "neutral", "300");
      border-radius: space(0.25);
      font-size: 14px;
      transition: ease-in-out 0.2s border;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;

      &:hover {
        border: 1px solid getvar($colors, "neutral", "500");
      }

      &:active {
        border: 1px solid getvar($colors, "primary", "500");
      }

      &:focus {
        outline: none;
        border: 1px solid getvar($colors, "primary", "500");
      }

      &::placeholder {
        color: getvar($colors, "neutral", "500");
        font-size: 14px;
      }
    }

    &__icon {
      position: absolute;
      width: space(2.5);
      height: 95%;
      bottom: -22px;
      right: 2px;
      background-color: white;
      transform: translateY(-50%);
      pointer-events: none;

      svg {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        box-sizing: border-box;
        height: 100%;
        padding: 5px;
        color: getvar($colors, "neutral", "600");
      }
    }
  }
}
