@import "src/themes/daikiri/styles/index.scss";
.event-date-time {
  .button--event-date-time__format--disabled {
    opacity: 0.5;
  }

  .Checkbox--event-date-time__timezone-checkbox {
    width: 94px;
    margin-top: 23px;
  }

  &__format {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: getvar($colors, "primary", "50");
    margin-bottom: 1.5rem;
    padding: 1rem 0;
    gap: 5px;
    @include md {
      justify-content: flex-start;
    }

    button {
      min-width: unset;
      width: 155px !important;
      margin: 0 !important;
      background-color: white !important;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 1.5rem !important;

      @include md {
        margin-left: 2rem !important;
      }
    }

    .button--hours__format--disabled {
      opacity: 1;
    }

    .button--hours__format--disabled {
      opacity: 0.5;
    }
  }

  &__timezone-selector {
    width: 100%;

    .Select__wrapper select {
      background-color: white;
    }
  }

  &__date-time-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: space(1);
    align-items: flex-end;
    margin-bottom: space(1);
  }

  &__timezone-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: space(1);
    //align-items: center;
    margin-bottom: space(1);
    height: 64px;
  }

  &__date-picker {
    position: relative;
    input {
      padding: space(0.5) space(1);
      height: fit-content;
      line-height: 24px;
    }
    .date-picker__input__icon {
      width: 32px;
      height: 32px;
      bottom: -9px;
    }
  }
}
