@import "src/themes/daikiri/styles/index.scss";
.reviews_container {
    display: flex;
    flex-direction: column;
    gap: 24px !important;
    width: 100% !important;
    max-width: 100% !important;

    p {
        margin-bottom: 0;
    }
}

.reviews-table_container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 8px;
}